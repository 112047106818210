<template>
  <div class="main">
    <div class="box">
      <div class="content">
       <div class="title">
         欢迎登录<br>
         零售药店全场景智控系统
       </div>
        <div class="menu">
          <van-row>
            <van-col span="8" ><div class="item" :class="{'active':userType==50}" @click="history(50)">管理员登录</div></van-col>
            <van-col span="8"><div class="item " :class="{'active':userType==40}" @click="history(40)">检查员登录</div></van-col>
          </van-row>
        </div>
<div class="form">
  <div class="item">
    <van-row type="flex" align="center">
      <van-col span="23" ><div><van-field v-model="userName" label="" placeholder="请输入手机号码" :clearable="true"/></div></van-col>

    </van-row>
  </div>
  <div class="item">
    <van-row type="flex" align="center">
      <van-col span="16" ><div><van-field v-model="authCode" label="" placeholder="请输入验证码" /></div></van-col>
      <van-col span="8"><div style="color: #038ABE" @click="send()">获取验证码 </div></van-col>
    </van-row>
  </div>
<p>{{ message }}</p>
</div>
        <div class="login"><van-button type="primary" round color="#038ABE" style="width: 30vh" @click="login()">登 录</van-button></div>
      </div>

    </div>
  </div>
</template>
<script>
import { sms,getLogin, zzdLogin, zzdBind,getUser } from '@/api/get'
import Vue from 'vue';
import { Button,Col, Row,Field,Toast } from 'vant';
Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Field);
Vue.use(Toast);
export default {
  data() {
    return {
      userType: 40,
      userName: "",
      authCode: "",
      openId: '',
      message: ''
    };
  },
  watch:{
    userType(){
     // console.log("a: "+val, oldVal);
    }
  },
  mounted () {
    this.handlZzdLogin()
    //this.queue(this.$route.meta.id,this.$route.meta.title,this.$route.path)
  },
  methods: {
    handlZzdLogin () {
      const _this = this
      _this.$dd.ready(function() {
      _this.$dd.getAuthCode({ corpId: '196729' }).then((result) =>{
          if (result) {
            window.localStorage.clear
            const toast = Toast.loading({
              message: '自动登录中...',
              forbidClick: true,
              duration: 0
            });
              if(result.code) {
                // _this.userName = result.code
                zzdLogin({ code: result.code }).then(res => {
                  toast.clear()
                  if (res.data.code === 200) {
                    try {
                      Toast('登录成功')
                      localStorage.userName=_this.userName
                      localStorage.userType=_this.userType
                      localStorage.Authorization=res.data.data.Authorization
                      localStorage.userId = res.data.data.userId
                      _this.$cookies.set("token",res.data.data.Authorization)
                      _this.$store.commit('setToken');
                      _this.$router.push({ path: '/', query: {id:0}})
                    } catch (e) {
                      // alert(JSON.stringify(e))
                    }
                  } else if (res.data.code === 6003) {
                    _this.openId = res.data.msg
                    Toast.fail('未获取到手机号，请输入手机号验证码登录绑定')
                  } else {
                    Toast.fail(res.data.msg)
                  }
                }).catch((err) => {
                  toast.clear()
                  Toast.fail(err.message || err.msg)
                })
              }
          }
        })
      })
    },
    send(){
    if (this.userName === '' || this.userName.length != 11) {
      Toast.fail('请输入正确的手机号')
      return false
    }
     sms(this.userName).then(res => {
       if (res.data.code === 200) {
        Toast('发送成功，请注意查收！')
         this.authCode = res.data.data
       } else {
         Toast.fail(res.data.msg)
       }
      })
    },
    user(){
      getUser().then(res => {
        if (res.data.code === 200){
          localStorage.storeId = res.data.data.storeId;
          localStorage.storeName = res.data.data.storeName;
          localStorage.name = res.data.data.nickName;
          localStorage.userId = res.data.data.accountId
          this.$store.commit('setUid',res.data.data.accountId);
          this.$router.push({ path: '/', query: {id:0}})
        }
      })
    },
    login(){
      if (this.openId) {
        let post ={
          phone: this.userName,
          code: this.authCode,
          openId: this.openId
        }
        zzdBind(post).then(res => {
          if (res.data.code === 200){
            localStorage.userName=this.userName;
            localStorage.userType=this.userType;
            localStorage.Authorization=res.data.data.Authorization;
            localStorage.userId = res.data.data.userId
            this.$cookies.set("token",res.data.data.Authorization)
            this.$router.push({ path: '/', query: {id:0}})
          }else{
            Toast.fail(res.data.msg)
          }
        })
      } else {
        if (this.userName === '' || this.userName.length != 11) {
          Toast.fail('请输入正确的手机号')
          return false
        }
        if (this.authCode === '') {
          Toast.fail('请输入手机验证码')
          return false
        }
        let post={
          userType: this.userType,
          userName: this.userName,
          authCode: this.authCode,
          phone: this.userName
        }
        getLogin(post).then(res => {
          if (res.data.code === 200){
            localStorage.userName=this.userName;
            localStorage.userType=this.userType;
            localStorage.Authorization=res.data.data.Authorization;
            this.$cookies.set("token",res.data.data.Authorization)
            this.$store.commit('setToken');
            this.user()
          }else{
            Toast.fail(res.data.msg)
          }
        })
      }
    },
    history(t) {
      this.userType=t
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />